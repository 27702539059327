<template>
  <div class="dashboard-wraper">
    <!-- Basic Information -->
    <div class="form-submit">
      <h4>Change Your Password</h4>
      <div class="alert alert-danger" v-if="has_error">Could not change your password.</div>
      <div class="alert alert-success" v-if="success">Successfully changed your password.</div>
      <form class="submit-section" @submit.prevent="submit">
        <div class="row">
          <div class="form-group col-lg-12 col-md-6">
            <label>Old Password</label>
            <input type="password" class="form-control" placeholder="Old Password" v-model="user.old_password" required />
          </div>

          <div class="form-group col-md-6">
            <label>New Password</label>
            <input type="password" class="form-control" placeholder="New Password" v-model="user.password" required />
          </div>

          <div class="form-group col-md-6">
            <label>Confirm New Password</label>
            <input type="password" class="form-control" placeholder="Confirm New Password" v-model="user.password_confirmation" required />
          </div>

          <div class="form-group col-lg-12 col-md-12">
            <button class="btn btn-theme-light-2 rounded" type="submit">
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      has_error: false,
      success: false
    }
  },

  methods: {
    submit() {
      this.has_error = false
      this.success = false

      this.$axios.post('/api/v1/my-account/change-password', this.user).then(() => {
        this.success = true
      }).catch(() => {
        this.has_error = true
      })
    }
  }
};
</script>
